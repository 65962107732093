export default {
    init() {
        const logoContainer = document.querySelectorAll(".logo-rows .logo-container");

        if (logoContainer) {
            logoContainer.forEach((container) => {
                let logos = container.getElementsByClassName("logo");
                let logosArr = [...logos];

                if (logosArr.length > 0) {
                    let modulo = logosArr.length % 3;
                    if (modulo === 0) {
                        let lastThreeLogos = logosArr.slice(-3, logosArr.length);
                        lastThreeLogos.forEach((logo) => {
                            logo.classList.add("remove-border-bottom")
                        })
                    } else if (modulo === 1) {
                        let lastLogo = logosArr.slice(-1, logosArr.length);
                        lastLogo.forEach((logo) => {
                            logo.classList.add("remove-border-bottom")
                        })
                    } else if (modulo === 2) {
                        let lastLogo = logosArr.slice(-2, logosArr.length);
                        lastLogo.forEach((logo) => {
                            logo.classList.add("remove-border-bottom")
                        })
                    }
                }
            })
        }
    },
}