import {head} from "lodash";
import React, {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";
import {CategorySwitcherStore} from "../Stores/CategorySwitcherStore";
import {Category, Month, Translations, Year} from "../Types/Category";
import {observer} from "mobx-react";


interface IProps {
    data: (Category | Year | Month)[];
    emptyState: string;
    onClick: () => void;
    isOpen: boolean;
    parameter: string;
    store: CategorySwitcherStore;
    translations: Translations;
}


const Dropdown = observer((props: IProps) => {
    const [renderItem, setRenderItem] = useState<Category | Year | Month>();
    const getItemForId = (itemId: string) => {
        return head(props.data.filter((item) => item.id === itemId));
    };
    const init = () => {
        if (props.parameter === "month" && props.store.currentMonthId) {
            setRenderItem(getItemForId(props.store.currentMonthId));
        }

        if (props.parameter === "year" && props.store.currentYearId) {
            setRenderItem(getItemForId(props.store.currentYearId));
        }

        if (props.parameter === "category" && props.store.currentCategoryId) {
            setRenderItem(getItemForId(props.store.currentCategoryId));
        }
    };


    const setSelectedItem = (item?: Category | Year | Month) => {
        if (props.parameter == "category") {
            props.store.setCategory(item);
        }
        if (props.parameter == "year") {
            props.store.setYear(item);
        }
        if (props.parameter == "month") {
            props.store.setMonth(item);
        }

        props.store.updateQueryParams();

        props.onClick();
    };

    useEffect(() => {
        void init();

    }, []);

    return (
        <div
            className={`dropdown  ${props.parameter} font-gothic-bold text-base-small md:text-base relative mr-3 md:mr-0 md:ml-4 ${props.parameter === "month" && !props.store.currentYearId ? "pointer-events-none text-grey-500" : "text-light"} `}>
            <button
                className={`dropdown-header ${props.isOpen ? "open" : ""} bg-transparent mb-4 py-2.5 pl-5 pr-2 border border-border-dark-mode rounded-full flex items-center focus:outline-none`}
                onClick={props.onClick}>
                {(renderItem) ?
                    <p>{renderItem.title}</p>
                    :
                    <p>{props.emptyState}</p>
                }
                <ReactSVG className="ml-2" src="/dist/images/ic_chevron_down.svg"/>
            </button>
            <div
                className={`dropdown-body ${props.isOpen ? "open" : ""}  z-10 py-1 bg-background-900 border border-border-dark-mode rounded-md absolute left-0 md:right-0 w-auto`}>
                <div className="dropdown-item" onClick={() => setSelectedItem(undefined)} key={null}>
                    <a className="flex cursor-pointer">{props.translations.all}</a>
                </div>
                {props.data.map((item, index) => (
                    <div className="dropdown-item" onClick={() => setSelectedItem(item)} key={index}>
                        <a className="flex px-4 py-2 cursor-pointer">{item.title}</a>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Dropdown;