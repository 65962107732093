import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export default {
    init(ModalSlider) {
        const content = document.querySelector(".content");
        const openmodal = document.querySelectorAll(".modal-open")
        const gallerySection = document.getElementById("gallery");

        if (gallerySection && ModalSlider) {
            if (openmodal) {
                for (let i = 0; i < openmodal.length; i++) {
                    openmodal[i].addEventListener("click", function (event) {
                        let index = openmodal[i].parentElement.dataset.index;
                        ModalSlider.slideTo(index, 0)
                        event.preventDefault()
                        toggleModal()
                    })
                }
            }

            const overlay = document.querySelector(".modal-overlay")
            overlay.addEventListener("click", toggleModal)

            let closemodal = document.querySelectorAll(".modal-close")

            if (closemodal) {
                for (let i = 0; i < closemodal.length; i++) {
                    closemodal[i].addEventListener("click", toggleModal)
                }
            }


            document.onkeydown = function (evt) {
                evt = evt || window.event
                let isEscape = false
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc")
                } else {
                    isEscape = (evt.keyCode === 27)
                }
                if (isEscape && document.body.classList.contains("modal-active")) {
                    toggleModal()
                }
            };
        }

        function toggleModal() {
            const body = document.querySelector("body")
            const modal = document.querySelector(".modal")
            modal.classList.toggle("opacity-0")
            modal.classList.toggle("pointer-events-none")
            body.classList.toggle("modal-active")

            if (body.classList.contains("modal-active")) {
                disableBodyScroll(content);
            } else {
                enableBodyScroll(content);
            }
        }
    },
}