import Swiper from "swiper";

let tabsSwipers = [];

export default {

    init(swiperElement, swiperOptions) {
        tabsSwipers.push(new Swiper(swiperElement, swiperOptions));

        let tabSliderSlides = document.querySelectorAll(".tabs-container .swiper-slide");

        if (tabSliderSlides.length > 0) {
            tabSliderSlides.forEach((slide) => {
                if (slide.classList.contains("active")) {
                    let index = slide.dataset.index;

                    tabsSwipers.forEach((swiper) => {
                        let swiperDomElem = swiper.$el[0];
                        swiperDomElem.style.opacity = 0;
                        swiper.slideTo(index, 0);
                        swiperDomElem.style.opacity = 1;
                    })
                }
            })
        }

    },
}
