import tailwindUtils from "./helpers/tailwind-utils";

export default {
    init() {
        const accordionHeads = document.getElementsByClassName("accordion-head");
        const accordionTables = document.getElementsByClassName("accordion-body-table");
        const md = tailwindUtils.getMQValue("md");
        let mediaQuery = window.matchMedia("(min-width: " + md + ")");

        if (accordionTables) {
            for (let i = 0; i < accordionTables.length; i++) {
                if (i == accordionTables.length - 1) {
                    let accordionTableElems = accordionTables[i].querySelectorAll(".accordion-row")

                    if (accordionTables.length % 2 == 0 && mediaQuery.matches) {
                        // length = even
                        accordionTableElems[accordionTableElems.length - 1].classList.add("remove-border-bottom")
                        accordionTableElems[accordionTableElems.length - 2].classList.add("remove-border-bottom")
                    } else {
                        // length = odd
                        accordionTableElems[accordionTableElems.length - 1].classList.add("remove-border-bottom")
                    }
                }
            }
        }

        if (accordionHeads) {
            for (let i = 1; i < accordionHeads.length; i++) {
                accordionHeads[0].classList.add("border-t");
            }
            const accordionHeadClick = (eventHappened) => {
                let targetClicked = eventHappened.currentTarget;
                // element open
                const accordionBody = targetClicked.nextElementSibling;
                // rotate Icon
                targetClicked.querySelector(".open-icon").classList.toggle("rotated");
                targetClicked.classList.toggle("active");

                if (accordionBody.style.maxHeight) {
                    accordionBody.style.maxHeight = null;
                }
                // others
                else {
                    const allAccordionBodies = document.getElementsByClassName("accordion-body");
                    for (let i = 0; i < accordionHeads.length; i++) {
                        accordionHeads[i].querySelector(".open-icon").classList.remove("rotated");
                    }
                    for (let i = 0; i < allAccordionBodies.length; i++) {
                        if (allAccordionBodies[i].style.maxHeight) {
                            allAccordionBodies[i].style.maxHeight = null;
                        }
                    }
                    //rotate Icon
                    targetClicked.querySelector(".open-icon").classList.toggle("rotated");
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                }
            }

            for (let i = 0; i < accordionHeads.length; i++) {
                accordionHeads[i].addEventListener("click", function (event) {
                    accordionHeadClick(event);
                });
            }
        }
    },
}
