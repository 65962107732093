import {Category, Month, Year} from "../Types/Category";
import {action, makeObservable, observable} from "mobx";

class CategorySwitcherStore {
    currentCategoryId?: string = undefined;
    currentYearId?: string = undefined;
    currentMonthId?: string = undefined;

    constructor() {

        makeObservable(this, {
            currentCategoryId: observable,
            currentYearId: observable,
            currentMonthId: observable,
            setYear: action,
            setMonth: action,
            setCategory: action,
        });
    }

    setCategory(item?: Category) {
        this.currentCategoryId = item ? item.id : undefined;
    }

    setYear(item?: Year) {
        this.currentYearId = item ? item.id : undefined;
    }

    setMonth(item?: Month) {
        this.currentMonthId = item ? item.id : undefined;
    }

    initialLoadFromQueryParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const category = urlParams.get("category");
        const year = urlParams.get("year");
        const month = urlParams.get("month");

        if (category) {
            this.currentCategoryId = category;
        }
        if (year) {
            this.currentYearId = year;
        }

        if (month) {
            this.currentMonthId = month;
        }


        this.updateQueryParams();
    }

    updateQueryParams() {
        const queryParams = new URLSearchParams(window.location.search);

        if (this.currentCategoryId) {
            queryParams.set("category", this.currentCategoryId);
        } else {
            queryParams.delete("category");
        }

        if (this.currentYearId) {
            queryParams.set("year", this.currentYearId);
        } else {
            queryParams.delete("year");
        }

        if (this.currentMonthId) {
            queryParams.set("month", this.currentMonthId);
        } else {
            queryParams.delete("month");
        }

        // if there is no valid year, we also drop the month because we need a year for the month
        if (this.currentYearId === undefined) {
            queryParams.delete("month");
            this.currentMonthId = undefined;
        }

        window.history.pushState(null, "", "?" + queryParams.toString());

        const sprigComponent = document.getElementById("news-category-sprig");

        if (sprigComponent) {
            sprigComponent.dispatchEvent(new Event("refresh"));
        }
    }
}

export {CategorySwitcherStore};