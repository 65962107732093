import React from "react";
import ReactDOM from "react-dom";
import CategorySwitcher from "./CategorySwitcher/CategorySwitcher";

// if you have a component which is not on each page available and you would like to lazy load
// check the DOM for it, then lazy load corresponding react component
// provide Suspense fallback (loading state)

export default {
    init() {

        const categorySwitch = document.getElementById("category-switch-container");

        if (
            categorySwitch &&
            categorySwitch.dataset.oldestYear &&
            categorySwitch.dataset.categories &&
            categorySwitch.dataset.categorySlug &&
            categorySwitch.dataset.translations
        ) {
            ReactDOM.render(
                <CategorySwitcher
                    oldestYear={categorySwitch.dataset.oldestYear}
                    categories={categorySwitch.dataset.categories}
                    categorySlug={categorySwitch.dataset.categorySlug}
                    translations={categorySwitch.dataset.translations}
                />, categorySwitch
            );
        }
    },
};



