import htmx from "htmx.org";

export default {
    init() {

        startLiveTicker();

        function startLiveTicker() {
            const livetickerSection = document.querySelector(".liveticker");
            const matchLiveticker = document.getElementById("match-liveticker");
            if (!livetickerSection && !matchLiveticker) {
                return;
            }

            const second = 1000;
            const minute = second * 60;
            const hour = minute * 60;
            const day = hour * 24;

            let matchDateElem = document.getElementById("match-date");

            if (!matchDateElem) {
                return;
            }

            let matchDateData = matchDateElem.dataset.matchdate;

            if (!matchDateData) {
                return;
            }

            let matchDate = new Date(matchDateData).getTime();

            let x = setInterval(() => {
                let now = new Date().getTime();
                let distance = matchDate - now;

                if (distance > 0) {
                    if (livetickerSection) {
                        let dateContainer = document.getElementById("date-container");
                        dateContainer.style.display = "flex";

                        document.getElementById("days").innerText = Math.floor(distance / (day)),
                            document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
                            document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
                            document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);
                    } else if (matchLiveticker) {
                        let dateContainer = document.getElementById("match-date-container");
                        dateContainer.style.display = "flex";
                    }
                } else {
                    if (livetickerSection) {
                        let dateContainer = document.getElementById("date-container");
                        let liveContainer = document.getElementById("live-container");
                        let dateMobileContainer = document.getElementById("match-date-mobile");
                        let liveMobileContainer = document.getElementById("live-mobile");

                        dateContainer.style.display = "none";
                        dateMobileContainer.style.display = "none";
                        liveContainer.style.display = "flex";
                        const matchIsOver = liveMobileContainer.dataset.matchIsOver;
                        if (matchIsOver === "1") {

                            liveMobileContainer.style.display = "none";
                        } else {
                            liveMobileContainer.style.display = "block";
                        }
                    } else if (matchLiveticker) {
                        let dateContainer = document.getElementById("match-date-container");
                        let liveContainer = document.getElementById("match-live-container");

                        dateContainer.style.display = "none";
                        liveContainer.style.display = "flex";
                    }
                    clearInterval(x);
                }
            }, 500)
        }

        htmx.on("htmx:afterSwap", function () {
            startLiveTicker();
        });
    },
}