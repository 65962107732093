import "intersection-observer";

export default {

    init(mediaQuery = "max-width: 767px") {

        let lazyloadImagesScroll = document.querySelectorAll(".js-lazy-image-scroll source, .js-lazy-image-scroll img");
        let lazyloadImages = document.querySelectorAll(".js-lazy-image source, .js-lazy-image img");
        let images = document.querySelectorAll(".js-lazy-image-scroll img, .js-lazy-image img");
        const mediaQueryList = window.matchMedia(mediaQuery);

        if (mediaQueryList.matches) {
            images.forEach(function (image) {
                if (image.dataset.mobilePlaceholder) {
                    image.src = image.dataset.mobilePlaceholder;
                }
            });
        }


        const imageObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    setSource(image);
                    imageObserver.unobserve(image);
                }
            });
        });

        if (lazyloadImagesScroll.length) {
            lazyloadImagesScroll.forEach(function (image) {
                imageObserver.observe(image);
            });
        }

        if (lazyloadImages.length) {
            lazyloadImages.forEach(function (image) {
                setSource(image);
            });
        }


        function setSource(element) {
            if (element.dataset && element.dataset.src) {
                element.src = element.dataset.src;
            }
            if (element.dataset && element.dataset.srcset) {
                element.srcset = element.dataset.srcset;
            }

            element.parentElement.classList.remove("js-lazy-image-scroll");
            element.parentElement.classList.remove("js-lazy-image");
        }
    },
}
