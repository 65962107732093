import tailwindUtils from "./helpers/tailwind-utils";

export default {
    currentOpenSubMenu: null,

    resetMenu() {
        const header = document.getElementById("header");

        if (header.classList.contains("open-tab")) {
            header.classList.remove("open-tab");
        }
        this.currentOpenSubMenu = null;
    },

    init() {
        const header = document.getElementById("header");
        const headerMenu = document.getElementById("header-menu-tabs");
        let backButton = document.querySelectorAll(".back-button");
        const lg = tailwindUtils.getMQValue("lg");

        if (headerMenu) {
            let tabs = headerMenu.querySelectorAll(".tab");

            window.addEventListener("resize", () => {
                // reset menu on resize
                this.resetMenu();
            });

            if (tabs.length > 0) {
                tabs.forEach(el => {
                    el.addEventListener("click", function () {
                        toggleClass(this, "active");
                        if (!header.classList.contains("open-tab")) {
                            header.classList.add("open-tab");
                        }
                        this.currentOpenSubMenu = this;
                    })
                })
            }

            if (backButton.length > 0) {
                backButton.forEach(btn => {
                    btn.addEventListener("click", function () {


                        let tabContentActive = document.querySelector(".open-tab .tab-content.active-tab-content");
                        // const timelineBackBtn = anime.timeline({ duration: 400, easing: "easeOutQuint" });
                        let tabButtons = document.querySelectorAll(".tab-button");

                        if (tabContentActive && window.matchMedia("(max-width:" + lg + "px)").matches) {

                            tabButtons.forEach((tabButton) => {
                                tabButton.classList.remove("remove-button");
                            })
                            tabContentActive.classList.remove("active-tab-content");
                            // timelineBackBtn
                            // 	.add({
                            // 		targets: ".tab-content.active-tab-content",
                            // 		translateX: "100%",
                            // 		complete: () => {
                            // 			tabButtons.forEach((tabButton) => {
                            // 				tabButton.classList.remove("remove-button");
                            // 			})
                            // 			tabContentActive.classList.remove("active-tab-content");
                            // 		},
                            // 	}, 200);
                        }

                        if (header.classList.contains("open-tab")) {
                            header.classList.remove("open-tab");
                        }

                        tabs.forEach(el => {
                            if (el.classList.contains("active")) {
                                el.classList.remove("active")
                            }
                        })
                    })
                })
            }
        }

        function toggleClass(elem, cls) {


            elem.classList.toggle(cls);

            siblings(elem).forEach(node => {
                node.classList.remove(cls)
            })

            if (elem.classList.contains("active") && window.matchMedia("(max-width:" + lg + "px)").matches) {

                // const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });
                let tabContent = elem.querySelector(".tab-content");
                let tabButtons = document.querySelectorAll(".tab-button");

                if (!tabContent.classList.contains("active-tab-content")) {
                    const headerMenuContainer = document.getElementById("header-menu-lang-container");
                    headerMenuContainer.scrollTo(0, 0);
                    tabContent.classList.add("active-tab-content");
                    tabButtons.forEach((tabButton) => {
                        tabButton.classList.add("remove-button");
                    })
                    // timeline
                    // 	.add({
                    // 		targets: ".active .tab-content",
                    // 		translateX: "0%",
                    // 		complete: () => {
                    // 			tabContent.classList.add("active-tab-content");
                    // 			tabButtons.forEach((tabButton) => {
                    // 				tabButton.classList.add("remove-button");
                    // 			})
                    // 		},
                    // 	}, 200);
                }
            }
        }

        function siblings(elem) {
            const nodes = [...elem.parentNode.children]
            return nodes.filter(node => node !== elem)
        }
    },
}