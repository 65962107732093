import anime from "animejs";

export default {
    init() {

        //////////// Navigation
        const html = document.getElementsByTagName("HTML")[0];
        const header = document.getElementById("header");
        const menu = document.getElementById("header-menu-tabs");
        const hamburger = document.getElementById("hamburger");
        let tabs;
        const dropdownButton = document.querySelector(".dropdown-button");
        let tabContents = document.querySelectorAll(".tab-content");

        if (dropdownButton) {
            dropdownButton.addEventListener("click", () => {
                let showDropdown = document.querySelector(".lang-switcher .dropdown-content");
                showDropdown.classList.toggle("show");
            })
        }

        if (menu) {
            tabs = menu.getElementsByClassName("tab");
        }

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        // We listen to the resize event
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);

            if (menu.classList.contains("open")) {
                menu.classList.remove("open");
                header.classList.remove("open");
                hamburger.classList.remove("close");
                html.classList.remove("stop-scrolling-content");

                if (tabs) {
                    tabs.forEach(tab => {
                        if (tab.classList.contains("active")) {
                            tab.classList.remove("active");
                        }
                    });
                }

                if (tabContents) {
                    tabContents.forEach((tabContent) => {
                        if (tabContent.classList.contains("active-tab-content")) {
                            tabContent.style.removeProperty("transform");
                        }
                    })
                }
            }
        });

        if (hamburger) {
            hamburger.addEventListener("click", function () {
                const timeline = anime.timeline({duration: 400, easing: "easeOutQuint"});

                // close menu
                if (menu.classList.contains("open")) {
                    html.classList.remove("stop-scrolling-content");
                    menu.classList.remove("open");
                    header.classList.remove("open");
                    hamburger.classList.remove("close");

                    if (tabContents) {
                        tabContents.forEach((tabContent) => {
                            if (tabContent.classList.contains("active-tab-content")) {
                                tabContent.style.removeProperty("transform");
                            }
                        })
                    }

                    if (tabs) {
                        tabs.forEach(tab => {
                            if (tab.classList.contains("active")) {
                                tab.classList.remove("active");
                            }
                        });
                    }

                    if (header.classList.contains("open-tab")) {
                        header.classList.remove("open-tab");
                    }

                    timeline.add({
                        targets: "#header-menu-tabs",
                        opacity: 0,
                        complete: () => {
                        },
                    });

                } else {
                    // open menu
                    const headerMenuContainer = document.getElementById("header-menu-lang-container");

                    html.classList.add("stop-scrolling-content");
                    menu.classList.add("open");
                    headerMenuContainer.scrollTo(0, 0);
                    header.classList.add("open");
                    hamburger.classList.add("close");


                    timeline.add({
                        targets: "#header-menu-tabs",
                        opacity: 1,
                        complete: () => {
                        },
                    });
                }
            });
        }
    },
}