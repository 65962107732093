import Swiper, {Autoplay, Controller, EffectFade, Navigation, Pagination} from "swiper";

let imageTabsSwipers = [];

export default {

    init(swiperElement, swiperOptions) {

        Swiper.use([Pagination, Autoplay, EffectFade, Navigation, Controller]);

        imageTabsSwipers.push(new Swiper(swiperElement, swiperOptions));

        if (imageTabsSwipers) {
            imageTabsSwipers.forEach((swiper) => {
                let swiperDomElem = swiper.$el[0];
                swiperDomElem.classList.add("opacity-100");
            })
        }

        let newsSliderSlides = document.querySelectorAll(".image-tabs-container .swiper-slide");

        if (newsSliderSlides.length > 3) {
            let buttonNext = document.querySelector(".image-tabs-container .swiper-button-next");
            let buttonPrev = document.querySelector(".image-tabs-container .swiper-button-prev");

            buttonNext.classList.remove("hidden");
            buttonPrev.classList.remove("hidden");
            buttonNext.classList.add("md:flex");
            buttonPrev.classList.add("md:flex");
        }

        if (newsSliderSlides) {
            newsSliderSlides.forEach((slide) => {
                if (slide.classList.contains("active")) {
                    let index = slide.dataset.index;

                    imageTabsSwipers.forEach((swiper) => {
                        let swiperDomElem = swiper.$el[0];
                        swiperDomElem.style.opacity = 0;
                        swiper.slideTo(index, 0);
                        swiperDomElem.style.opacity = 1;
                    })
                }
            })
        }
    },
    destroy(swiperToDestroy) {
        if (swiperToDestroy) {
            swiperToDestroy.destroy();
        } else {
            imageTabsSwipers.forEach(function (element) {
                element.destroy();
            });
        }
    },
    update(swiperToUpdate) {
        if (swiperToUpdate) {
            swiperToUpdate.update();
        } else {
            imageTabsSwipers.forEach(function (element) {
                element.update();
            });
        }
    },
}