import Swiper, {Autoplay, Controller, EffectFade, Navigation, Pagination} from "swiper";
import tailwindUtils from "./helpers/tailwind-utils";
import gallery from "./gallery.js";

let successCardsSwiper;
let heroNewsSwiper;
let newsTeaserSwiper;
let newsSliderSwiper;
let gallerySliderSwiper;
let gallerySliderOverlaySwiper;

export default {
    init() {

        const md = tailwindUtils.getMQValue("md");
        const lg = tailwindUtils.getMQValue("lg");

        Swiper.use([Pagination, Autoplay, EffectFade, Navigation, Controller]);

        // for swiper to work uncomment the vendors loading in base-web-layout.twig

        // Success Cards Swiper
        successCardsSwiper = new Swiper(".success-cards-container", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 12,
            breakpoints: {
                [lg]: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
        });

        // Hero News Swiper
        heroNewsSwiper = new Swiper(".hero-news-container", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 8000,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

        // News Teaser Swiper
        newsTeaserSwiper = new Swiper(".news-teaser-container", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 12,
            breakpoints: {
                [lg]: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
            },
        });

        // News Slider Swiper
        newsSliderSwiper = new Swiper(".news-slider-container", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 12,
            breakpoints: {
                [lg]: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        let newsSliderSlides = document.querySelectorAll(".news-slider-container .swiper-slide");

        if (newsSliderSlides.length >= 3) {
            let buttonNext = document.querySelector(".news-slider-container .swiper-button-next");
            let buttonPrev = document.querySelector(".news-slider-container .swiper-button-prev");

            buttonNext.classList.remove("hidden");
            buttonPrev.classList.remove("hidden");
            buttonNext.classList.add("lg:flex");
            buttonPrev.classList.add("lg:flex");
        }

        // Gallery Swiper
        gallerySliderSwiper = new Swiper(".gallery-slider-container", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 12,
            breakpoints: {
                [md]: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        gallerySliderOverlaySwiper = new Swiper(".gallery-slider-overlay-container", {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 12,
            grabCursor: true,
            breakpoints: {
                [md]: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        if (gallerySliderOverlaySwiper) {
            gallery.init(gallerySliderOverlaySwiper);
        }
    },
    disable() {
        if (successCardsSwiper.length) {
            successCardsSwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            successCardsSwiper.destroy();
        }
        if (heroNewsSwiper.length) {
            heroNewsSwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            heroNewsSwiper.destroy();
        }
        if (newsTeaserSwiper.length) {
            newsTeaserSwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            newsTeaserSwiper.destroy();
        }
        if (newsSliderSwiper.length) {
            newsSliderSwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            newsSliderSwiper.destroy();
        }
        if (gallerySliderSwiper.length) {
            gallerySliderSwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            gallerySliderSwiper.destroy();
        }
        if (gallerySliderOverlaySwiper.length) {
            gallerySliderOverlaySwiper.forEach(function (element) {
                element.destroy();
            });
        } else {
            gallerySliderOverlaySwiper.destroy();
        }
    },
}
