import React from "react";
import {Category, Month, Translations, Year} from "../Types/Category";
import Dropdown from "./Dropdown";
import {orderBy} from "lodash";
import {CategorySwitcherStore} from "../Stores/CategorySwitcherStore";
import {makeObservable, observable} from "mobx";

interface Props {
    oldestYear: string;
    categories: string;
    categorySlug: string;
    translations: string;
}

interface State {
    openRowIndex: number;
}

class CategorySwitcher extends React.Component<Props, State> {
    categorySwitcherStore: CategorySwitcherStore;
    categoriesArray = [];
    yearArray: Year[] = [];
    now = new Date();
    currentYear: Year = {
        id: String(this.now.getFullYear()),
        title: String(this.now.getFullYear()),
    };
    translationsArray?: Translations = undefined;
    monthArray: Month[] = [];
    finalCategoriesArray: Category[] = [];

    constructor(props: Props) {
        super(props);
        // set initial state in constructor directly
        this.state = {
            openRowIndex: 0,
        };

        makeObservable(this, {
            categorySwitcherStore: observable,
        });


        this.categoriesArray = JSON.parse(this.props.categories);
        this.translationsArray = JSON.parse(this.props.translations);

        if (this.translationsArray) {
            this.createMonthArray(this.translationsArray);
        }

        this.finalCategoriesArray = this.categoriesArray.filter((category: any) => {
            // we exclude the "current" category because it is displayed es "all" in filter dropdown
            if (category.title !== "Aktuell" && category.title !== "Latest") {
                return category;
            }
        }).map((item: any) => {
            return {
                id: String(item.id), // id is saved as number,
                title: item.title,
            } as Category;
        });

        const oldestYear: Year = {
            id: this.props.oldestYear,
            title: this.props.oldestYear,
        };

        this.yearArray = orderBy(this.calculateYears(oldestYear, this.currentYear), "id", "desc");


        this.categorySwitcherStore = new CategorySwitcherStore();
        this.categorySwitcherStore.initialLoadFromQueryParams();
    }

    calculateYears(startYear: Year, endYear: Year) {
        const dateArray: Year[] = [];
        let currentDate: Year = startYear;
        const startYearAsInt = parseInt(startYear.id);
        const endYearAsInt = parseInt(endYear.id);
        for (let year = startYearAsInt; year <= endYearAsInt; year++) {
            currentDate = {
                id: String(year),
                title: String(year),
            };
            dateArray.push(currentDate);
        }

        return dateArray;
    }


    toggleOpenDropdown = (idx: number) => {
        this.setState({openRowIndex: idx});
        if (idx === this.state.openRowIndex) {
            this.setState({openRowIndex: 0});
        }
    };

    createMonthArray = (translations: Translations) => {
        if (translations) {
            this.monthArray = [
                {id: "01", title: translations.january},
                {id: "02", title: translations.february},
                {id: "03", title: translations.march},
                {id: "04", title: translations.april},
                {id: "05", title: translations.may},
                {id: "06", title: translations.june},
                {id: "07", title: translations.july},
                {id: "08", title: translations.august},
                {id: "09", title: translations.september},
                {id: "10", title: translations.october},
                {id: "11", title: translations.november},
                {id: "12", title: translations.december},
            ];
        }
    };

    render() {

        return (
            <div className="category-switcher">
                <div className="dropdowns flex flex-wrap">
                    {(this.props.categorySlug === "Aktuell" || this.props.categorySlug === "Latest") && this.translationsArray &&
                        <Dropdown
                            data={this.finalCategoriesArray}
                            translations={this.translationsArray} emptyState={this.translationsArray.emptyCategory}
                            isOpen={this.state.openRowIndex === 1} store={this.categorySwitcherStore}
                            onClick={() => this.toggleOpenDropdown(1)} parameter="category"/>
                    }
                    {this.translationsArray &&
                        <Dropdown data={this.yearArray} translations={this.translationsArray}
                                  emptyState={this.translationsArray.emptyYear} isOpen={this.state.openRowIndex === 2}
                                  store={this.categorySwitcherStore} onClick={() => this.toggleOpenDropdown(2)}
                                  parameter="year"/>
                    }
                    {this.translationsArray &&
                        <Dropdown data={this.monthArray} translations={this.translationsArray}
                                  emptyState={this.translationsArray.emptyMonth} isOpen={this.state.openRowIndex === 3}
                                  store={this.categorySwitcherStore} onClick={() => this.toggleOpenDropdown(3)}
                                  parameter="month"/>
                    }
                </div>
            </div>
        );
    }
}

export default CategorySwitcher;